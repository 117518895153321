<template lang="pug">
#list-sort.list
  pre-loader.list-preloader(:show="!vehiclesLoaded")
    template(v-if="vehicles.length")
      .container
        transition-group(name="list" tag="div" class="row")
          .col-xl-4.col-lg-4.col-md-6(
            v-for="(product, index) in vehicles" :key="index + 0"
          )
            v-product(:vehicle="product")
        pre-loader(:show="!loadMorePreloader")
          .row(v-if="moreIsLoaded")
            .col-md-12.m-t-20.m-b-30.text-center
              div.list-load-more(@click="loadMore") {{ $t("list.loadMore") }}
                i.fas.fa-chevron-down.arrow
        AlertBox(v-if="showAlert"
          :title="$t('alertBox.title')"
          :content="$t('alertBox.content')"
        )
      .legal-block(v-if="terms")
        .container
          .legal-block-text(v-html="terms")
    template(v-else)
      .container
        .col-md-12.empty.text-center
          p.title {{ $t("list.noResult") }}
          p.text {{ $t("list.noResult2") }}

  div(:class="vehiclesLoaded ? 'element-hide' : ''")
    .container
      .row
        .col-md-12.m-b-30.m-t-20.data-not-loaded.text-center {{ $t("list.loading") }}
</template>

<script>
  import {mapState, mapGetters, mapActions} from 'vuex';

  import VProduct from '@/components/blocks/Product';
  import AlertBox from '@/components/common/AlertBox';

  export default {
    name: 'List',
    data: () => ({
      loadMorePreloader: true
    }),
    computed: {
      ...mapState('list', [
        'vehicles',
        'pagination',
        'vehiclesLoaded'
      ]),
      ...mapGetters('list', [ 'nextPage', ]),
      ...mapGetters('filter', ['getFinanceFormId']),
      ...mapState('reseller', [ 'resellerInfo', ]),
      moreIsLoaded() {
        return this.pagination.current_page < this.pagination.total_pages
      },
      terms() {
        const termsObj = this.resellerInfo.builder.disclaimerTexts.data.find(item => item.finance_form_id === this.getFinanceFormId)

        return termsObj ? termsObj.terms_desc : ''
      },
      showAlert() {
        return this.resellerInfo.market.lang === 'sv'
      },
    },
    methods: {
      ...mapActions('list', [ 'getVehicles', ]),
      loadMore() {
        this.loadMorePreloader = false;
        
        this.getVehicles(this.nextPage).then(() => {
					this.loadMorePreloader = true;
        });
      }
    },

    components: {
      VProduct,
      AlertBox
    }
  }
</script>

<style lang="sass">
  @import '@/style/components/List.sass'
</style>
