<template lang="pug">
div
  router-link.product-card(
      :to="{ name: 'product', params: {collectionType: $route.params.collectionType, type: replaceSpaces(vehicle.type), brand: replaceSpaces(vehicle.brand), model: replaceSpaces(vehicle.model), name: replaceSpaces(vehicle.name),} , query: {id: vehicle.id, financeform: replaceSpaces(finance_form_name), } }"
    )
    .product-card-image(:class="{'campaign-offset': isCampaign }")
      v-image.image(:image-source="image")
      template(v-if="isCampaign")
        .product-card-price-symbol
          .product-card-price-symbol-text {{ vehicle.campaign.text }}
    .product-card-info
      div
        .product-card-title
          .product-card-title-name {{ vehicle.model }}
        .product-card-description
          span
            strong {{ vehicle.grade }}&nbsp;
            | {{ vehicle.name }}
      div
        .product-card-title-delivery-days(v-if="hasDeliveryTime")
          strong {{ deliveryTimeText }}:
          span.pl-1 {{ deliveryDaysCountFrom() }}
          span(v-if="!['Private rental', 'Corporate rental'].includes(finance_form_name)") - {{ deliveryDaysCountTo() }}
        .product-card-price
          .product-card-price-text
            span.num {{ standardPrice }}
            span.mes {{ currencySymbol }}/{{ $t('mon') }}
          router-link.btn.btn-og.invert.btn-hover(
            :to="{ name: 'product', params: {collectionType: $route.params.collectionType, type: replaceSpaces(vehicle.type), brand: replaceSpaces(vehicle.brand), model: replaceSpaces(vehicle.model), name: replaceSpaces(vehicle.name),} , query: {id: vehicle.id, financeform: replaceSpaces(finance_form_name), } }"
          ) {{ chooseBtnText }}

        template(v-if="country.code === 'dk'")
          .m-t-10
            table.info-table
              tbody
                tr
                  td.info-label
                    strong {{ $t("info.cashDeposit") }}:
                  td.info-value
                    strong {{ nonRefundableCashPayment }} {{ currencySymbol }}
                tr
                  td.info-label
                    strong {{ $t("info.totalLeasingCost") }}:
                  td.info-value
                    strong {{ totalLeasingCost }} {{ currencySymbol }}
                tr
                  td.info-label
                    strong {{ $t("info.totalCost") }}:
                  td.info-value
                    strong {{ totalCostFor12Months }} {{ currencySymbol }}
                tr
                  td.info-suffix(colspan="2")
                    | {{ $t("info.totalCostSuffix") }}
</template>

<script>
  import { mapState } from 'vuex'

  import { getGearName, getDaysOrWeeks, formatPrice, } from '@/helpers/helpers.js';

  import VImage from '@/components/helps/Image';

  export default {
    name: 'Product',
    props: {
      vehicle: {},
    },
    computed: {
      ...mapState('reseller', {
        hasDeliveryTime: state => state.resellerInfo?.settings?.deliveryTime,
        chooseBtnText: state => state.customStyle.listItem?.choose_btn_text || '',
        deliveryTimeText: state => state.customStyle.listItem?.delivery_time_text || '',
        minDaysFromOrder: state => state.resellerInfo.settings?.calendar?.rental.pickup_min_days_from_order || 0,
        market: state => state.resellerInfo?.market || {},
        currencySymbol: state => state.resellerInfo?.market?.currency?.symbol || 'kr',
        country: state => state.resellerInfo?.market?.country || {}
      }),
      ...mapState('filter', ['finance_form_name']),
      image() {
        let image = ''
        if (this.vehicle.colors) {
          let defaultVehicleColor = this.vehicle.colors.data.find(vehicleColor => vehicleColor.thumbnail);

          image =
            defaultVehicleColor?.image ||
            defaultVehicleColor?.modelColor?.image?.image
            || (this.vehicle.images && this.vehicle.images.data[0] ? this.vehicle.images.data[0].image : '')
        }

        return image
      },
      gear() {
        return this.$t(getGearName(this.vehicle.gear));
      },
      isCampaign() {
        let { campaign, } = this.vehicle;

        return campaign && !!Object.keys(campaign).length && campaign.ongoing
      },
      standardPrice() {
        return this.formatPrice(this.vehicle.standardPrice);
      },
      filtersKeys() {
        return Object.keys(this.filters);
      },
      totalLeasingCost() {
        return this.formatPrice(this.vehicle.totalCost)
      },
      totalCostFor12Months() {
        return this.formatPrice(this.vehicle.standardPrice * 12 + this.vehicle.nonRefundableCashPayment)
      },
      nonRefundableCashPayment() {
        return this.formatPrice(this.vehicle.nonRefundableCashPayment)
      }
    },
    methods: {
      formatPrice,
			replaceSpaces(text) {
				return text.replace(/ /g, '-').toLowerCase();
      },
      getDays(days, flag) {
        const daysParam = getDaysOrWeeks(days, flag, this.market.lang);

        return `${daysParam.number} ${daysParam.text}`;
      },
      deliveryDaysCountFrom () {
        let daysFrom = null;

        if (this.vehicle.colors && this.vehicle.colors.data.length) {
          const getDefaultVehicleColor = this.vehicle.colors.data.find(item => item.default);

          daysFrom = getDefaultVehicleColor?.delivery_days_from || this.vehicle.colors.data[0].delivery_days_from;
        } else {
          if (['Private rental', 'Corporate rental'].includes(this.finance_form_name)) {
            daysFrom = this.vehicle.deliveryTime.days_count && this.vehicle.deliveryTime.days_count !== null ?
            this.vehicle.deliveryTime.days_count : this.minDaysFromOrder;
          } else {
            daysFrom = this.vehicle.deliveryTime.from && this.vehicle.deliveryTime.from !== null ?
            this.vehicle.deliveryTime.from : this.vehicle.deliveryTime.days_count;
          }
        }

        return !['Private rental', 'Corporate rental'].includes(this.finance_form_name) ?
        this.getDays(daysFrom, false) : `från ${this.getDays(daysFrom, 'short')}`;
      },
      deliveryDaysCountTo () {
        let daysTo = null;

        if (this.vehicle.colors && this.vehicle.colors.data.length) {
          daysTo = this.vehicle.colors.data.find(item => item.default).delivery_days_to
        } else {
          daysTo = this.vehicle.deliveryTime.to
        }

        return this.getDays(daysTo, 'short');
      },
    },
    components: {
      VImage
    }
  }
</script>

<style lang="sass">
@import '@/style/components/Product.sass'
</style>

<style lang="sass" scoped>
.info-table
  width: 100%
  font-size: 0.95em
  border-spacing: 0

  .info-label
    padding: 2px 4px 2px 0
    white-space: nowrap

  .info-value
    white-space: nowrap
    text-align: right

  .info-suffix
    font-size: 0.9em
    color: #666
    padding-top: 2px
</style>
